import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FirebaseContext } from 'context';
import { format } from 'date-fns';

import {
  ProtectedRoute,
  AnalyticsEmojiBar,
  AnalyticsInteractionBar,
  AnalyticsChartSection,
  AnalyticsUrlSection,
  Button,
  LoadingSpinner
} from 'components';

function AnalyticsDashboard({ pageContext }) {
  const { eid, colors, speakers, startTime, endTime, streamDuration } = pageContext;
  const { firebase } = useContext(FirebaseContext);
  const [sendingCertificates, setSendingCertificates] = useState(false);
  const [certificatesSent, setCertificatesSent] = useState(false);
  const [participants, setParticpants] = useState([]);

  const populateEventParticipantsList = (result) => {
    if (!result.empty) {
      setParticpants((currrentValue) => [
        ...currrentValue,
        ...result.docs.map((doc) => {
          const { name, email, uid } = doc.data();
          return {
            name,
            email,
            uid
          };
        })
      ]);
    }
  };

  useEffect(() => {
    if (firebase) {
      firebase.fetchAllEventParticipants({ eid }).then(populateEventParticipantsList);
    }
    return () => setParticpants([]);
  }, [firebase]);

  const getFormattedDate = ({ startTime: _startTime, endTime: _endTime }) => {
    let formattedDate;

    const startDate = format(new Date(_startTime), 'do');
    const endDate = format(new Date(_endTime), 'do');
    const startMonth = format(new Date(_startTime), 'LLLL');
    const endMonth = format(new Date(_endTime), 'LLLL');

    if (startMonth === endMonth) {
      if (startDate === endDate) {
        // 1st scenario: If the startTime and endTime months are the same and the dates are the
        // same then we know it's a one day event, so we format it to 'January 31st, 2022', for example.
        formattedDate = `${format(new Date(_startTime), 'LLLL')} ${format(
          new Date(_endTime),
          'do, yyyy'
        )}`;
      } else if (startDate !== endDate) {
        // 2nd scenario: If the startTime and endTime months are the same but the dates are
        // different, e.g. January 30th and January 31st, so we format it to 'Janaury 30th - 31st, 2022'.
        formattedDate = `${format(new Date(_startTime), 'LLLL do')} - ${format(
          new Date(_endTime),
          'do, yyyy'
        )}`;
      }
    } else if (startMonth !== endMonth) {
      // 3rd scenario: If the months are different, e.g. January 31st and February 1st,
      // so we format it to 'January 31st - February 1st, 2022'.
      formattedDate = `${format(new Date(_startTime), 'LLLL do')} - ${format(
        new Date(_endTime),
        'LLLL do, yyyy'
      )}`;
    }

    return formattedDate;
  };

  const handleSendAttendanceCerts = async () => {
    // Uncomment the below code to mock accounts for testing.
    // Replace with your own email address and uid.
    const _participants = [];
    for (let i = 1; i <= 12; i++) {
      _participants.push({
        name: `Test${i}`,
        email: 'conor.d@agencyx.ie',
        uid: 'j4PShgvIS1VSaH3NWTMfjKKwa3b2'
      });
    }

    const date = getFormattedDate({ startTime, endTime });

    try {
      setSendingCertificates(true);
      if (participants.length <= 100) {
        // Have one cloud function call send all the certs to the participants
        const result = await firebase.sendAttendanceCerts({
          participants: _participants,
          eid,
          title: pageContext.name,
          speakers: speakers.map((speaker) => ({
            name: speaker.name,
            position: speaker.position,
            title: speaker.title,
            image: speaker.image.childImageSharp.gatsbyImageData.images.fallback.src
          })),
          date,
          colors,
          streamDuration
        });

        // eslint-disable-next-line no-console
        console.log('Result:', result);
      } else if (participants.length > 100) {
        // Paginate cloud function calls
        const amountOf100ParticpantSegments = Math.floor(participants.length / 100);
        const remainingParticipants = participants.length % 100;

        const promises = [];

        const getPaginatedFunctionCalls = (paginatedParticipants) =>
          firebase.sendAttendanceCerts({
            participants: paginatedParticipants,
            eid,
            title: pageContext.name,
            speakers: speakers.map((speaker) => ({
              name: speaker.name,
              position: speaker.position,
              title: speaker.title,
              image: speaker.image.childImageSharp.gatsbyImageData.images.fallback.src
            })),
            date,
            colors,
            streamDuration
          });

        for (let i = 0; i < amountOf100ParticpantSegments; i++) {
          promises.push(getPaginatedFunctionCalls(participants.slice(i * 100, (i + 1) * 100)));
        }

        promises.push(getPaginatedFunctionCalls(participants.slice(-remainingParticipants)));

        await Promise.all(promises);
      }
      setCertificatesSent(true);
    } catch (error) {
      console.error(error.message);
      setCertificatesSent(false);
    } finally {
      setSendingCertificates(false);
    }
  };

  return (
    <ProtectedRoute>
      <Wrapper>
        <p style={{ fontWeight: 'bold' }}>Analytics Dashboard</p>
        <AnalyticsBars>
          <AnalyticsInteractionBar eid={eid} />
          <AnalyticsEmojiBar eid={eid} />
        </AnalyticsBars>
        <AnalyticsChartSection eid={eid} />
        <AnalyticsUrlSection eid={eid} />
        <AnalyticsAttendanceCert>
          {sendingCertificates ? (
            <LoadingSpinner style={{ color: `${colors.tertiary}`, width: '4rem' }} />
          ) : (
            <Button
              colors={colors}
              width="400px"
              type="button"
              onClick={handleSendAttendanceCerts}
              disabled={certificatesSent}>
              {!certificatesSent ? 'Send Attendance Certificates' : 'Sent'}
            </Button>
          )}
        </AnalyticsAttendanceCert>
      </Wrapper>
    </ProtectedRoute>
  );
}
const Wrapper = styled(motion.div)`
  align-items: center;
  color: #004d9a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8.5rem;
  width: 100vw;
  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h4 {
    font-size: 3.625rem;
  }
  p {
    font-size: 1.25rem;
    text-align: center;
    width: 200px;
  }
`;

const AnalyticsBars = styled.section`
  display: flex;
  flex-direction: column;
  margin: 4.25rem 0 2.25rem 0;
  width: 100%;
`;

const AnalyticsAttendanceCert = styled.section`
  margin-bottom: 3rem;
`;

export default AnalyticsDashboard;
